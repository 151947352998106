.timeInput {

    input {
        --tw-border-opacity: 1;
        border-color: rgb(209 213 219 / var(--tw-border-opacity));
        border-radius: 0.375rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    }

    fieldset {
        border-color: rgb(209 213 219 / var(--tw-border-opacity)) !important;
    }
}
