.expandedSvg path {
	fill: #0e92ea;
}

.select {
	outline: none;
	box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), 0 0 0 1px #6366f1;
}

.scrollable {

	overflow-y: scroll;

	&::-webkit-scrollbar {
		width: 8px;
		cursor: pointer;
	}

	/* Track */
	&::-webkit-scrollbar-track {
		background: transparent;
		cursor: pointer;
	}

	/* Handle */
	&::-webkit-scrollbar-thumb {
		background: #0E92EA;
		border-radius: 5px;
		cursor: pointer;
	}

	/* Handle on hover */
	&::-webkit-scrollbar-thumb:hover {
		background: #6366f1;
		cursor: pointer;
	}
}

