.ag-root-wrapper {
	border: 1px solid #e5e7eb;
	border-radius: 8px;
}

.ag-header {
	border: none;
}
.ag-header-cell-comp-wrapper {
	overflow-x: visible;
}
.ag-center-cols-container,
.ag-center-cols-clipper {
	min-height: 60px !important;
}
.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within, .ag-ltr .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected), .ag-ltr .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group, .ag-ltr .ag-cell-range-single-cell, .ag-ltr .ag-cell-range-single-cell.ag-cell-range-handle, .ag-rtl .ag-cell-focus:not(.ag-cell-range-selected):focus-within, .ag-rtl .ag-context-menu-open .ag-cell-focus:not(.ag-cell-range-selected), .ag-rtl .ag-full-width-row.ag-row-focus:focus .ag-cell-wrapper.ag-row-group, .ag-rtl .ag-cell-range-single-cell, .ag-rtl .ag-cell-range-single-cell.ag-cell-range-handle {
	border-color: transparent;
}

.scrollable {
	overflow-y: auto;
}
.scrollable::-webkit-scrollbar {
	width: 4px;
	cursor: pointer;
	/* Track */
}
.scrollable::-webkit-scrollbar-track {
	background: transparent;
	cursor: pointer;
	/* Handle */
}
.scrollable::-webkit-scrollbar-thumb {
	background: #0E92EA;
	border-radius: 5px;
	cursor: pointer;
	/* Handle on hover */
}
.scrollable::-webkit-scrollbar-thumb:hover {
	background: #6366f1;
	cursor: pointer;
}

/*.ag-header-cell-comp-wrapper::-webkit-scrollbar {*/
/*    display: none;*/
/*}*/

/*.ag-root ::-webkit-scrollbar {*/
/*    display: none;*/
/*}*/
/*.ag-root ::-webkit-scrollbar-button {*/
/*    display: none;*/
/*}*/

/*.ag-root ::-webkit-scrollbar-track {*/
/*    display: none;*/
/*}*/

/*.ag-root ::-webkit-scrollbar-thumb {*/
/*    display: none;*/
/*}*/
