.input:before {
    content: '';
    position: absolute;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.2);
    background-color: white;
    transition: .2s ease-in-out;
}

.input:checked {
    background-color: #0E92EA;
}

.input:checked:before {
    left: 1.25rem;
}
